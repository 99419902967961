import { defineStore } from 'pinia'

import type {
  VideoCallStatus,
  VideoCallScreenStatus,
  VideoCallDirection,
  VideoCallPrivateUser,
  VideoCallRemoteUser,
  VideoCallMessage,
  VideoCallPayloadRegister,
  VideoCallPeer,
  VideoCallGenderFilter,
} from '../types'

type VideoCallStoreState = {
  status: VideoCallStatus
  isVideoCallScreenMounted: boolean
  screenStatus: VideoCallScreenStatus
  direction: VideoCallDirection
  entryPath: string
  callerId: number
  receiverId: number
  timerActive: boolean
  timerSec: number
  genderFilter: VideoCallGenderFilter
  privateUser: VideoCallPrivateUser | undefined
  remoteUser: VideoCallRemoteUser | undefined
  messages: VideoCallMessage[]
  register: VideoCallPayloadRegister | undefined
  remoteMediaStream: Ref<MediaStream | undefined>
}

const remoteMediaStream = ref<MediaStream>()
let peer: VideoCallPeer | undefined

export const useVideoCallStore = defineStore('videoCall', {
  state: (): VideoCallStoreState => {
    return {
      status: '',
      isVideoCallScreenMounted: false,
      screenStatus: '',
      direction: '',
      entryPath: '',
      callerId: 0,
      receiverId: 0,
      timerActive: false,
      timerSec: 0,
      genderFilter: 'female',
      privateUser: undefined,
      remoteUser: undefined,
      messages: [],
      register: undefined,
      remoteMediaStream,
    }
  },
  getters: {},
  actions: {
    reset() {
      this.status = ''
      // this.target = ''
      this.timerActive = false
      this.timerSec = 0
      this.remoteUser = undefined
      this.messages = []
      // this.videoStatus = ''
      this.register = undefined
      // this.blur = true
      remoteMediaStream.value = undefined
    },
    resetConnection() {
      this.status = ''
      this.remoteUser = undefined
      this.messages = []
      // this.videoStatus = ''
      // this.blur = false
      remoteMediaStream.value = undefined
    },
    resetPrivateCall() {
      this.direction = ''
      this.entryPath = ''
      this.callerId = 0
      this.receiverId = 0
      this.privateUser = undefined
    },
    remoteMediaStreamSet(stream: MediaStream | undefined) {
      remoteMediaStream.value = stream
    },
    peerSet(_peer: VideoCallPeer | undefined) {
      peer = _peer
    },
    peerGet() {
      return peer
    },
  },
})
